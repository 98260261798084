import * as React from "react";
import Layout from "../components/common/layout";
import Meta from "../components/common/meta/SEO";
import { Script } from "gatsby";

const ContactUs = () => {
  return (
      <Layout>
          <section className="w-full grid grid-cols-1 md:grid-cols-2 gap-16 pt-20 px-8">
            {/* Content */}
            <div>
                <h1 className="dx-h1 font-bold">お問い合わせ</h1>
                <p className="mt-8 dx-p2">
                    お問い合わせやサービスデモンストレーションのご要望は、フォームをご利用ください。
                </p>
                <p className="mt-8 dx-p2">
                    ご利用のインターネット環境によりフォームが表示されない場合がございます。もしも、入力フォームが表示されない場合は、こちらのメールアドレスへお問い合わせください。info@digitalaccels.com
                </p>
            </div>
            {/* End of Content */}
            {/* Form */}
            <div className="md:mt-[-15px]">
              <div id="contact-form">
                <Script id={`freshworksform-${new Date().getTime()}`} dangerouslySetInnerHTML={{ __html: `
var sc = document.createElement('script');
sc.src = 'https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/6bb61a9349bc8c65f0492830f4cf66243f6258d875600dd73990710469babf86/form.js'
sc.crossOrigin = 'anonymous'
sc.id = 'fs_6bb61a9349bc8c65f0492830f4cf66243f6258d875600dd73990710469babf86'
document.getElementById('contact-form').appendChild(sc);

new MutationObserver(function(mutationsList, observer) {
  mutationsList.forEach(mutation => {
    if (mutation.addedNodes) {
      var inputElements = document.getElementsByName('contact[custom_field][cf_web_form]');
      if (inputElements.length > 0) {
        inputElements[0].value = 'https://secureprivacy.dxable.com/contact-us/';
        var parent = inputElements[0].parentNode;
        parent.classList.add('fserv-hidden');
      }
      mutation.addedNodes.forEach(node => {
        var css = node.querySelector ? node.querySelector('link[href*="webforms.css"]') : null;
        if (css) {
          observer.disconnect();
          css.addEventListener('load', function() {
            setTimeout(function() {
              loop: for (var i = 0; i < document.styleSheets.length; i++) {
                var styleSheet = document.styleSheets[i];
                if (styleSheet.href && styleSheet.href.includes('webforms.css')) {
                  var rules = styleSheet.rules;
                  for (var j = 0; j < rules.length; j++) {
                    var rule = rules[j];
                    if (rule.selectorText && rule.selectorText.includes('.hidden,')) {
                      var cssText = rule.cssText.replace('.hidden,', '.fs-webform-container .hidden,');
                      styleSheet.deleteRule(j);
                      styleSheet.insertRule(cssText, j);
                      break loop;
                    }
                  }
                }
              }
            });
          });
        }
      });
    }
  });
}).observe(document.body, { childList:true, subtree: true });
          ` }} />
              </div>
              <p><a target="_blank" href="https://www.digitalaccels.com/privacy-policy">個人情報保護方針</a>についてご確認の上、ご同意いただける場合は上記の項目にチェックを入れてご送信ください。</p>
            </div>
            {/* End of Form */}
          </section>
    </Layout>
  );
};

export default ContactUs;

export const Head = ({ location }) => {
  const meta = {
    title: "お問い合わせ｜Secure Privacy正規販売代理店 DXable",
    description: "Secure Privacyのサービスに関するお問い合わせは、こちらのフォームからお願いします。折り返しご連絡を差し上げます。",
    pathname: location.pathname,
  }
  return (<Meta meta={meta} />)
}